<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Enthalpy of Reactions - Prelab Exercise Part II</h2>

    <p class="mb-3">
      Use the proper graphing method (see the Graphing Techniques in your lab manuals for more
      information regarding graphing), including metric graph paper, title, labeling of axes, units
      and scaling, to plot a graph of temperature versus time of the following data:
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px; width: 350px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Temperature (}^{\circ}\text{C)}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Time (minutes)}$" />
          </td>
        </tr>
        <tr v-for="dataPoint in tempData" :key="dataPoint.temp">
          <td style="vertical-align: middle; text-align: center">
            <latex-number :number="dataPoint.temp" />
          </td>
          <td style="vertical-align: middle; text-align: center">
            <latex-number :number="dataPoint.time" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-3">Upload a picture of your graph below.</p>

    <p class="mb-2 pl-4"><b>Accepted file types</b>: JPG, JPEG, and PNG</p>
    <p class="mb-0 pl-4"><b>Max File Size</b>: 15 MB</p>

    <p class="ml-n2">
      <v-file-input v-model="attachments" multiple />
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'UPEICalorimetryPreLabPt2V2',
  components: {
    LatexNumber,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      attachments: [],
      tempData: [
        {temp: '30', time: '6'},
        {temp: '35', time: '8'},
        {temp: '40', time: '10'},
        {temp: '45', time: '12'},
        {temp: '50', time: '14'},
      ],
    };
  },
};
</script>
